.main-layout {
  min-height: 100vh;
}


/**
 * ESTILOS PARA INPUTS
 * */
/*Añade un margin a los iconos del prefix */
.ant-input-affix-wrapper-lg {
  .ant-input-prefix {
    margin-right: 14px !important;
  }
}

input,
textarea {
  color-scheme: dark;
}

/*
* Clases globales que se pueden implementar en cualquier parte del sistema
* Son para mover elementos a una cierta posicion o aplicar espacios
*/
.overflow-x {
  min-width: 600px;
  max-width: 700px;
  overflow-x: scroll;
}

.d-block {
  display: block !important;
}

.border {
  border: solid 1px red;
}

.no-border {
  border: none !important;
}

.no-strong {
  font-weight: 300 !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-center {
  display: block;
  margin: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.flex-column {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-left-column {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-right-column {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-left-column-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.flex-left-column-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.float-left {
  float: left;
}

.heigth-100 {
  height: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-0{
  padding-left: 0 !important;
}

.pr-0{
  padding-right:0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.pd-1 {
  padding: 1rem !important;
}

.pd-2 {
  padding: 2rem !important;
}

.pd-3 {
  padding: 3rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-05 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pl-1 {
  padding-left: 1.5rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pl-4 {
  padding-left: 4rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.text-center,
.text-center .ant-form-item-label {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-white {
  color: #FFF !important;
}

.text-gray {
  color: #858997 !important;
}

.text-gray-dark {
  color: #222222 !important;
}

.text-purple {
  color: #5C54E0 !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-12,
.font-12 * {
  font-size: 12px !important;
}

.font-13,
.font-13 * {
  font-size: 13px !important;
}

.width-100,
.width-100 .ant-upload,
.w-100 {
  width: 100%;
}


.width-95 {
  width: 95%;
}

.bg-white {
  background: #FFFFFF;
}

.disabled-link {
  pointer-events: none;
}


.pointer-hand {
  cursor: pointer !important;
}


@media(max-width: 576px){
  .ant-col-xs-0{
    display: none;
  }
}

@media(min-width: 578px) and (max-width: 768px){
  .ant-col-sm-0{
    display: none;
  }
}

@media(min-width: 769px) and (max-width: 991px){
  .ant-col-md-0{
    display: none;
  }
}