
.btn-green-money{
	display: flex;
	justify-content: center;
	align-items: center;
	background: #51B56D;
	border: none !important;
	margin-bottom: 20px;
	height: 34px;
	width: 34px;
}

.btn-green-money:hover{
	background: #51B56D;
	border: none !important;
}
.btn-green-money:focus{
	background: #51B56D;
	border: none !important;
}

.btn-fast-link{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 170px;
	width: 170px;
	border-radius: 12px;
	background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0.05) 138.29%) !important;
	backdrop-filter: blur(5.73932px);
	margin-top: 1rem;
}

.btn-fast-link svg{
	margin-top: 16px;
}


.dashboard-button-container{

}

@media (max-width: 1320px) {
  .dashboard-button-container {
    flex-direction: column;
  }
}

