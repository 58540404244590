
.card-resumen{
	background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0.05) 138.29%) !important;
}

.card-resumen .title{
	font-weight: 400;
	font-size: 14px;
}

.card-resumen .monto{
	font-weight: 700;
	font-size: 24px;
	margin-top: 12px;
	margin-bottom: 12px;
}

.card-resumen .monto small{
	font-weight: 300;
	font-size: 16px;
}



.card-transaccion-reciente{
	background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0.05) 138.29%) !important;
	backdrop-filter: blur(5.73932px);
	border-radius: 12px;
	margin-bottom: 6px;
}

.card-transaccion-reciente .text-concepto{
	font-family: 'Cairo';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.card-transaccion-reciente .text-info{
	font-family: 'Cairo';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	opacity: 0.5;
}

.card-transaccion-reciente .text-monto{
	font-weight: 700;
	font-size: 18px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}


.card-cuenta::before{
	content: '';
	background-image: url("/public/img/background/mask-card.svg");
	background-position: center !important; 
  	background-repeat: no-repeat !important; 
  	background-size: cover !important;
	position:absolute;
    top:0px;
    left:0px;
    right: 0;
    bottom: 0;
    border-radius: 18px;
    z-index: -11;
}
.card-cuenta{
	background: linear-gradient(132.47deg, rgba(255, 255, 255, 0.2) -247.68%, rgba(255, 255, 255, 0) 177.37%);
	backdrop-filter: blur(40px);
	border-radius: 18px;
	width: 100%;
	max-width: 340px;
	min-height: 200px;
	margin-top: 1rem;
}

.card-cuenta .cuenta,
.card-cuenta .text-name{
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.card-cuenta .balance{
	margin-top: 30px;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.card-cuenta .saldo{
	font-weight: 700;
	font-size: 26px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.card-cuenta .saldo{
	font-weight: 700;
	font-size: 26px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.card-cuenta .cuenta{
	margin-top: 20px;
}

.card-cuenta-big{
	border-radius: 18px 0 0 0;
	height: calc(100vh - 64px - 40px);
	overflow-y: hidden;
	position: fixed;
	bottom: 0;
}

.card-cuenta-big .title{
	font-size: 22px;
	font-weight: 700;
	letter-spacing:  0.02em;
}

.card-cuenta-big .cuentas-beneficiarios-culumn{
/*	border: solid 1px red;*/
	max-height: 400px;
	overflow-y: scroll;
	scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

	/* Estilos específicos para navegadores WebKit */
.cuentas-beneficiarios-culumn::-webkit-scrollbar {
  width: 0.5em;
}

.cuentas-beneficiarios-culumn::-webkit-scrollbar-track {
  background-color: transparent;
}

.cuentas-beneficiarios-culumn::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.row-cuenta{
	margin-bottom: 1rem;
}

.row-cuenta .text-concepto{
	font-weight: 700;
	font-size: 18px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
}

.row-cuenta .text-info{
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	opacity: 0.7;
}


@media (max-width: 992px) {
  .card-cuenta-big {
    position: unset;
  }
}
