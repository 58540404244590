.auth{
	min-height: 100vh !important;
  	background-position: center; 
  	background-repeat: no-repeat; 
  	background-size: cover;
  	display: flex;
  	justify-content: center;
  	align-items: center;
		
	.card{
		max-width: 540px;
		width: 100%;
		border-radius: 20px !important;
	}
}


