.card-resumen {
    background: linear-gradient(125.86deg, rgba(255, 255, 255, 0.2) -267.85%, rgba(255, 255, 255, 0.05) 138.29%) !important;

    .title {
        font-weight: 400;
        font-size: 14px;
    }

    .monto {
        font-weight: 700;
        font-size: 22px;
        margin-top: 12px;
        margin-bottom: 12px;

        small {
            font-weight: 300;
            font-size: 16px;
        }
    }

}

.card-cuenta {
    border-radius: 20px 0 0 20px;

    .component-list {
        .ant-list-items>.ant-list-item>.ant-row {
            margin: 0;
        }
    }
}

.card-credit-card {
    backdrop-filter: blur(40px);
    border-radius: 18px;
}

.card-template {

    max-width: 320px !important;
    min-width: 180px;

    .card-bg-cover {
        display: block;
        margin: auto;
        height: 100px;
        background-color: #A1D707;

        .align-end {
            float: right;
            margin: 5px;
        }

        .float-right{
            position: relative;
            float:right
        }
    }

    .card-avatar {
        position: relative;
        top: -70px;
        margin: auto;
        display: block;
    }

    .card-grid-80-20 {
        padding: 2px;
        width: 70%;
        min-width: 50%;
        text-align: center;
        border: none !important;
    }

    .card-grid-20-80 {
        padding: 2px;
        width: 30%;
        text-align: center;
        border: none !important;
    }

    .grid-noborder {
        border: none !important;
        box-shadow: none;

        .ant-card-grid {
            border: none !important;
            box-shadow: none;

        }
    }

    .card-title-separated {
        letter-spacing: 6px;
        color: #DADADA;
        text-align: center;
        border-bottom: 1px solid #A1D707;
        cursor: pointer;
    }
}

.list-rules{
    width: 600px!important;
    border:1px solid #DADADA;

    .ant-list-header{
        border: none;
        background: rgb(204, 0, 109,0.5);
        padding-left:5px;
        border-radius: 3px;
    }
    .ant-list-item .borderless{
        border: none;
    }
}