.content {
  .main {
    padding: 32px
  }

  .card {
    padding-right: 0px !important;
  }
}

.component-list {
  &.ant-list-split .ant-list-header {
    border-bottom: none;
    padding: 16px;
  }

  .ant-list-items {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }

  .component-list-item {
    &.ant-list-item {
      padding: 0;
      border-bottom: none !important;
    }

    &>.ant-card>.ant-card-body {
      padding: 10px 16px;
    }

    & .card-list {
      background-color: rgba($color: #ffffff, $alpha: 0.08);
      border-radius: 12px;

      &.collapse-padding > .ant-card-body{
        padding: 10px 16px 10px 41px !important;
      }
    }
  }
}

.menu-propiedades {

  background-color: transparent !important;
  margin: 1em 0 !important;
  width: 100%;
  max-width: calc(100% - 172px) !important;
  display: inline-block !important;

  &.ant-menu-horizontal {
    border-bottom: none !important;
  }
}



.btn-delete-status {
  background: rgb(255, 103, 103) !important;
  border-color: rgba(255, 255, 255, 0.05) !important;
  margin-top: 5px;
}

.checkbox-text-resalt{
  font-size: 16px;
  color: #A1D707;
  font-weight: 800;
}

.btn-comision.ant-btn-primary,
.btn-comision.ant-btn-primary:hover,
.btn-comsion.ant-btn-primary:focus{
  background: #F700D0;
  border-color: #F700D0;
}

@media (max-width: 1330px) {
  .space-cuenta-comision {
    flex-direction: column;
  }
}