.sidebar{
	padding: 1rem;
	background-color: transparent;
	border-right: solid 1px #2b2b2e;
}

.sidebar .ant-menu{
	background: transparent;
	border: none;
}

.sidebar .ant-menu-item{
	margin: 1rem auto;
	padding: 0px !important;
	width: 50px;
	height: 50px;
	border-radius: 50px;
}

.sidebar .ant-menu-item:hover{
	background-color: #A1D70750;
}

.sidebar .ant-menu-item .ant-menu-title-content{
	display: none;
}

.sidebar .ant-menu-item .anticon,
.sidebar .ant-menu-item svg{
	margin: 0 auto !important;
	font-size: 22px;
}

.sidebar .ant-menu-item .anticon,
.sidebar .ant-menu-item .ant-menu-item-icon{
	color: #bfbfbf;
}

.sidebar .ant-menu-item.ant-menu-item-selected{
	background-color: #A1D707;
}


@media(max-width: 992px){
	.sidebar{
		padding: 1rem 0 ;
		position: fixed;
		z-index: 1000;
		background-color: #202023;
		height: 100%;
	}
}

